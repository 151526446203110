let googleMapJS;
googleMapJS = function () {

    let map;
    let defaultOpts = {
        zoom: 14
    }

    this.centerPlace = function (location, zoom) {
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode({'address': location}, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                map.setCenter(results[0].geometry.location);
                if (zoom) {
                    map.setZoom(zoom);
                } else {
                    map.setZoom(defaultOpts.zoom);
                }
            }
        });
    }

    this.run = function (opts, pins) {
        const el = document.getElementById(opts.div);
        let infowindow = new google.maps.InfoWindow();

        const mapOptions = {
            scrollwheel: false,
            disableDefaultUI: true,
            zoom: opts.zoom ? opts.zoom : defaultOpts.zoom,
            zoomControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL
            },
            center: new google.maps.LatLng(opts.center.lat, opts.center.lng),
            styles: [
                {
                    "featureType": "water",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#d3d3d3"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "stylers": [
                        {
                            "color": "#808080"
                        },
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#b3b3b3"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#ffffff"
                        },
                        {
                            "weight": 1.8
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#d7d7d7"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#ebebeb"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#a7a7a7"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#efefef"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#696969"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#737373"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#d6d6d6"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {},
                {
                    "featureType": "poi",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#dadada"
                        }
                    ]
                }
            ]
        };

        if (el.outerHTML.length > 0) {
            map = new google.maps.Map(el, mapOptions);
        }

        $.each(pins, function (key, value) {
            const marker = new google.maps.Marker({
                position: new google.maps.LatLng(value['lat'], value['lng']),
                map: map,
                icon: '../assets/images/pin.png',
                animation: google.maps.Animation.DROP
            });

            if (value['iw']) {
                google.maps.event.addListener(marker, 'click', function () {
                    infowindow.close();
                    infowindow.setContent('<div class="map-iw-head">' + value['iwHead'] + '</div><div class="map-iw-body">' + value['iwBody'] + '</div>');
                    infowindow.open(map, marker);
                });

                if (value['iwVisible']) {
                    infowindow.setContent('<div class="map-iw-head">' + value['iwHead'] + '</div><div class="map-iw-body">' + value['iwBody'] + '</div>');
                    infowindow.open(map, marker);
                }
            }
        });

    };

    this.resize = function () {
    };

    this.load = function () {
    };

};
const __googleMapJS = new googleMapJS();
