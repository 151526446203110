let toggleMenuJS;
toggleMenuJS = function () {

    const btn = $('[data-toggle-btn]');
    const backdrop = $('.site-foot__backdrop');

    const getScrollbarWidth = function () {

        // Creating invisible container
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll'; // forcing scrollbar to appear
        outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
        document.body.appendChild(outer);

        // Creating inner element and placing it in the container
        const inner = document.createElement('div');
        outer.appendChild(inner);

        // Calculating difference between container's full width and the child width
        const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

        // Removing temporary elements from the DOM
        outer.parentNode.removeChild(outer);

        return scrollbarWidth;

    }

    const clickBackdrop = function () {
        backdrop.on('click', function () {
            hide($('.is-active[data-toggle-btn]'));
        })
    }

    const show = function (el) {
        const type = el.attr('data-toggle-btn');
        const menu = $('[data-toggle-menu="' + type + '"]');

        el.addClass('is-active');
        menu.addClass('is-active').delay(50).queue(function (next) {
            menu.addClass('is-show');
            next()
        });

        if (el.attr('data-scroll-lock') !== 'false') {
            bodyScrollLock.disableBodyScroll(document.querySelector('[data-toggle-menu]'));
            $('body').css('padding-right', getScrollbarWidth());
        }

        if (el.attr('data-backdrop') !== 'false') {
            backdrop.fadeIn();
        }
    }

    const hide = function (el) {
        const type = el.attr('data-toggle-btn');
        const menu = $('[data-toggle-menu="' + type + '"]');

        el.removeClass('is-active');
        menu.removeClass('is-show').delay(50).queue(function (next) {
            menu.removeClass('is-active');
            next()
        });

        if (el.attr('data-scroll-lock') !== 'false') {
            bodyScrollLock.enableBodyScroll(document.querySelector('[data-toggle-menu]'));
            $('body').attr('style', '');
        }

        if (el.attr('data-backdrop') !== 'false') {
            backdrop.fadeOut();
        }
    }

    const init = function () {
        btn.on('click', function () {
            const $this = $(this);
            const type = $this.attr('data-toggle-btn');

            if ($this.hasClass('is-active')) {
                hide($this);
            } else {
                show($this);
            }
        });
    };

    this.run = function () {
        init();
        clickBackdrop();
    };

    this.resize = function () {
    };

    this.load = function () {
    };

};
const __toggleMenuJS = new toggleMenuJS();
