let cartJS;
cartJS = function () {

    const btn = $('.js-cart-toggle-btn');
    const cart = $('.site-cart');

    const toggle = function () {
        btn.on('click', function () {
            cart.toggleClass('is-active');
        });

        if ($(window).width() < window_sm) {
            cart.removeClass('is-active');
        }
    }

    this.run = function () {
        toggle();
    };

    this.resize = function () {
    };

    this.load = function () {
    };

};
const __cartJS = new cartJS();
