let colorPickerJS;
colorPickerJS = function () {

    const init = function () {
        const selector  = $('[data-color-selector]');
        const colorArea = $('[data-color-area]');

        selector.on('change', function () {
            const colorCode = $(this).find(':selected').attr('data-color');
            const index = $('[data-color="' + colorCode + '"]');

            $('.product-gallery__list').flickity('selectCell', index.index());
            $('.js-item-color-image').val(index.find('img').attr('src'))

            colorArea.css({
                'background-color': colorCode,
            });
        })
    };

    this.run = function () {
        init();
    };

    this.resize = function () {
    };

    this.load = function () {
    };

};
const __colorPickerJS = new colorPickerJS();
