let flickityCustomNavJS;
flickityCustomNavJS = function () {

    this.run = function () {
        const prev = $('[data-js-flickity-prev-btn]');
        const next = $('[data-js-flickity-next-btn]');

        prev.on('click', function () {
            $('[data-flickity-id="'+ $(this).attr('data-js-flickity-prev-btn') +'"]').flickity('previous');
        });

        next.on('click', function () {
            $('[data-flickity-id="'+ $(this).attr('data-js-flickity-next-btn') +'"]').flickity('next');
        });
    };

    this.resize = function () {
    };

    this.load = function () {
    };

};
const __flickityCustomNavJS = new flickityCustomNavJS();